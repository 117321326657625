
import { Component, Vue } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import { User } from '@/types/users'
import SpinnerFull from '@/components/SpinnerFull.vue'
import ErrorOverlay from '@/components/ErrorOverlay.vue'
import SocketStatus from '@/components/SocketStatus.vue'
import env from '@/env'
import { RestError } from '@/rest/errors'

@Component({ components: { SpinnerFull, ErrorOverlay, SocketStatus } })
export default class Admin extends Vue {
  @Getter('apps/loading') appsLoading: boolean
  @Action('apps/LOAD_CONFIGS') loadConfigs: () => Promise<void>
  @Action('users/LOAD_CURRENT_USER') loadCurrentUser: () => Promise<void>
  @Getter('users/current') currentUser!: User | null
  @Getter('users/currentLoading') userLoading: boolean
  @Action('auth/LOGOUT') logoutAuth: () => Promise<void>
  @Action('global/START_WEBSOCKET') startWebsocket: () => Promise<void>
    @Action('campaigns/LOAD_LOCKS') loadLocks: () => Promise<void>
    @Action('i18n/LOAD_LOCALES') loadLocales: () => Promise<void>
  @Getter('permissions/canReadUsers') canReadUsers!: boolean

  env = env
  error: string|null = null

  get loading (): boolean {
    return this.appsLoading || this.userLoading
  }

  get tabs (): string[][] {
    const tabs = [
      ['Campaigns', '/campaign'],
      ['Media', '/media'],
      ['Apps', '/apps']
    ]
    if (this.canReadUsers) {
      tabs.push(['Users', '/user'])
    }

    return tabs
  }

  async created () {
    try {
      await this.loadCurrentUser()
    } catch (err) {
      if (err instanceof RestError && err.name === 'Unauthorized') {
        this.$router.replace({
          name: 'login',
          query: {
            next: this.$route.fullPath
          }
        })
        return
      }
      throw (err)
    }
    try {
      await this.loadConfigs()
      await this.loadLocks()
      await this.loadLocales()
      await this.startWebsocket()
    } catch (err) {
      this.error = (err as Error).toString()
      throw (err)
    }
  }

  async logout () {
    await this.logoutAuth()
    this.$router.push({ name: 'login' })
  }

  get darkMode () {
    return this.$vuetify.theme.dark
  }

  set darkMode (value: boolean) {
    this.$vuetify.theme.dark = value
    localStorage.setItem('dark-mode', value.toString())
  }
}
