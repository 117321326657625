import { ActionContext } from 'vuex'

import { RootState, AuthState } from '../types'
import { TokenInfo } from '@/types/auth'
import { lockedAction } from '../locking'
import rest from '@/rest'

const LOCAL_STORAGE_TOKEN = 'cms_token'

const state: AuthState = {
  tokenInfo: null,
  loading: false
}

type AuthActionContext = ActionContext<AuthState, RootState >

const mutations = {
  SET_LOADING (state: AuthState, value: boolean) {
    state.loading = value
  },
  SET_TOKEN_INFO (state: AuthState, tokenInfo: TokenInfo) {
    state.tokenInfo = tokenInfo

    if (tokenInfo !== null) {
      localStorage.setItem(LOCAL_STORAGE_TOKEN, JSON.stringify(tokenInfo))

      return
    }

    localStorage.removeItem(LOCAL_STORAGE_TOKEN)
  }
}

const actions = {
  LOGIN: lockedAction(
    async (ctx: AuthActionContext, data: {email: string; password: string}) => {
      ctx.commit('SET_LOADING', true)
      try {
        await rest.login(data.email, data.password)
      } finally {
        ctx.commit('SET_LOADING', false)
      }
    }
  ),
  LOGOUT: lockedAction(
    async (ctx: AuthActionContext) => {
      await rest.logout()
      ctx.commit('SET_TOKEN_INFO', null)
    }
  ),
  INIT (ctx: AuthActionContext) {
    const savedToken = localStorage.getItem(LOCAL_STORAGE_TOKEN)
    if (savedToken) {
      ctx.commit('SET_TOKEN_INFO', JSON.parse(savedToken))
    }
    rest.initAuth(
      () => ctx.state.tokenInfo,
      (info: TokenInfo) => ctx.commit('SET_TOKEN_INFO', info)
    )
  },
  REFRESH_TOKEN_IF_NEEDED: lockedAction(
    async (ctx: AuthActionContext) => {
      const tokenInfo = ctx.state.tokenInfo
      if (!tokenInfo) {
        return
      }
      const now = +new Date()
      const expiresIn = tokenInfo.accessExpires - now
      if (expiresIn > 120000) {
        return
      }
      console.log('Refresh now, expires in', expiresIn / 1000, 'seconds')
      await rest.refreshToken(tokenInfo.refreshToken)
    }
  )
}

export default {
  namespaced: true,
  state,
  mutations,
  getters: {
    tokenInfo: (state: AuthState) => state.tokenInfo,
    getTokenInfo: (state: AuthState) => () => state.tokenInfo,
    accessToken: (state: AuthState) => state.tokenInfo ? state.tokenInfo.accessToken : null,
    loading: (state: AuthState) => state.loading
  },
  actions
}
