
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { formatDistanceToNow } from 'date-fns'

@Component
export default class TimeDeltaHuman extends Vue {
  @Prop({ type: [Date, String] }) date!: Date | string
  @Prop({ type: Number, default: 30 }) updateSeconds!: number

  updateInterval!: number
  distance = ''

  get dateObj (): Date {
    if (typeof this.date === 'string') {
      return new Date(this.date)
    }
    return this.date
  }

  created () {
    this.distance = this.makeDelta()
    this.updateInterval = window.setInterval(() => {
      this.distance = this.makeDelta()
    }, this.updateSeconds * 1000 + Math.ceil(Math.random() * 1000))
  }

  @Watch('date')
  onPropertyChanged () {
    this.distance = this.makeDelta()
  }

  destroyed () {
    window.clearInterval(this.updateInterval)
  }

  makeDelta () {
    return formatDistanceToNow(this.dateObj, { addSuffix: true, includeSeconds: true })
  }
}
