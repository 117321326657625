
import { Component, Vue } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'

@Component
export default class Login extends Vue {
  email = ''
  password = ''
  @Action('auth/LOGIN') login!: (data: {email: string; password: string}) => void
  @Getter('auth/loading') loading!: boolean

  async submit () {
    let nextUrl = (this.$route.query.next || '/').toString()
    if (nextUrl) {
      const resolved = this.$router.resolve(nextUrl)
      if (resolved.location.path === this.$route.path) {
        nextUrl = '/'
      }
    }
    await this.login({ email: this.email, password: this.password })
    this.$router.replace(nextUrl)
  }
}
