import { ActionContext } from 'vuex'
import { RootState } from '../types'
import rest from '@/rest'
import { RestError } from '@/rest/errors'
import { MusicServiceLookupItem, TracklistInfo, TracklistParams } from '@/types/music'

interface MusicState {
  tracklist: TracklistInfo | null
  tracklistPreview: TracklistInfo | null
  searchErrorMsg: string | null
  loading: boolean

}

const state: MusicState = {
  tracklist: null,
  tracklistPreview: null,
  searchErrorMsg: null,
  loading: false
}

type MusicActionContext = ActionContext<MusicState, RootState >

type SpotifyMusicItem = SpotifyApi.PlaylistObjectFull | SpotifyApi.AlbumObjectFull

const mutations = {
  SET_TRACKLIST_PREVIEW (state: MusicState, data: TracklistInfo | null) {
    state.tracklistPreview = data
  },

  SET_TRACKLIST (state: MusicState, data: TracklistInfo | null) {
    state.tracklist = data
  },

  SET_SEARCH_ERROR (state: MusicState, value: string|null) {
    state.searchErrorMsg = value
  },

  SET_LOADING (state: MusicState, value: boolean) {
    state.loading = value
  }

}

const actions = {
  async _LOAD_TRACKLIST_FROM_SEARCH (ctx: MusicActionContext, p: {search_value?: string|null; item_ids?: string[]|null; dryRun?: boolean}) {
    const stateKey = p.dryRun ? 'SET_TRACKLIST_PREVIEW' : 'SET_TRACKLIST'
    ctx.commit('SET_SEARCH_ERROR', null)

    let result: TracklistInfo | null = null
    try {
      result = await rest.music.getTracklistPreview(ctx.rootGetters['campaigns/currentId'], { search_value: p.search_value, item_ids: p.item_ids })
    } catch (error) {
      if (error instanceof RestError && error.status && error.status < 500) {
        ctx.commit('SET_SEARCH_ERROR', error.simpleMessage)
      } else {
        throw (error)
      }
    }
    if (!result) {
      ctx.commit(stateKey, null)
      ctx.commit('SET_SEARCH_ERROR', 'ID not found')
      return
    }
    ctx.commit(stateKey, result)

    if (p.dryRun) { return }

    try {
      await rest.music.setTracklist(ctx.rootGetters['campaigns/currentId'], result.options)
    } catch (error) {
      ctx.commit(stateKey, null)
      throw (error)
    }
  },

  async LOAD_PREVIEW_FROM_SEARCH (ctx: MusicActionContext, p: {search_value?: string|null; item_ids?: string[]|null}) {
    ctx.commit('SET_LOADING', true)
    try {
      await ctx.dispatch('_LOAD_TRACKLIST_FROM_SEARCH', { ...p, dryRun: true })
    } finally {
      ctx.commit('SET_LOADING', false)
    }
  },

  async SAVE_TRACKLIST_FROM_SEARCH (ctx: MusicActionContext, p: {search_value?: string|null; item_ids?: string[]|null}) {
    ctx.commit('SET_LOADING', true)
    try {
      await ctx.dispatch('_LOAD_TRACKLIST_FROM_SEARCH', { ...p, dryRun: false })
    } finally {
      ctx.commit('SET_LOADING', false)
    }
    ctx.commit('SET_TRACKLIST_PREVIEW', null)
    ctx.dispatch(
      'snackbar/SHOW_MESSAGE',
      { content: 'Tracklist updated!', color: 'success' },
      { root: true }
    )
  },

  async LOAD_TRACKLIST (ctx: MusicActionContext) {
    ctx.commit('SET_LOADING', true)
    try {
      ctx.commit('SET_TRACKLIST', await rest.music.getTracklist(ctx.rootGetters['campaigns/currentId']))
    } finally {
      ctx.commit('SET_LOADING', false)
    }
  }

}

const getters = {
  tracklist: (state: MusicState) => state.tracklist,
  tracklistPreview: (state: MusicState) => state.tracklistPreview,
  searchErrorMsg: (state: MusicState) => state.searchErrorMsg,
  loading: (state: MusicState) => state.loading
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
